import React, {useState} from "react"
import { BackButton, Heading } from "../../components"
import { graphql } from "gatsby"
import { Carousel } from "../../components"
import styled from "styled-components"

const GraphicsPage = ({ data }) => {
  const [idx, setIdx] = useState(0);
  const [tab, setTab] = useState("game");

  const photos = data.allFile.edges;
  const tabs = [{name: "In-Game Presentation", tab: "game"}, {name: "Others", tab: "other"}];
  const filtered = photos.filter(photo => photo.node.relativeDirectory === `portfolio/graphicdesign/${tab}`);

  const tabSwitch = (tab) => {
    setIdx(0)
    document.getElementById("carousel").style.transform = 'translate(0)'
    setTab(tab)
  }

  return (
    <div className="container">
      <Heading title="Graphics" cursive="Portfolio" />
      <BackButton />
      <Nav>
        {tabs.map(tabb => (
          <button key={tabb.name} onClick={()=> tabSwitch(tabb.tab)}>{tabb.name}</button>
        ))}
      </Nav>
      <Carousel photos={filtered} idx={idx} setIdx={setIdx} />
    </div>
  )
}

export default GraphicsPage

export const Head = () => <title>TMOY || Graphics</title>

export const photosQuery = graphql`
  query {
    allFile(filter: {extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativePath: {regex: "/portfolio/graphicdesign/" }}) {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`

const Nav = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  margin-top: -2rem;

  button {
    font-size: 12pt;
    border-radius: 10px;
    background-color: ${props => props.theme.bg};
    width: 100%;
    border: 1px solid ${props => props.theme.secondary};
    padding: 10px;
    margin: 10px;
    font-weight: bold;

    :hover {
      border: 1px solid ${props => props.theme.accent};
      color: ${props => props.theme.accent};
    }
  }
`